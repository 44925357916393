import { AbstractControl } from '@angular/forms';
import lodash from 'lodash';

/**
 * Method responsible for verifying that the field is mandatory
 * and filled in, used more to display the balloon please edit;
 */
export const needToFillTheField = (
    form: AbstractControl,
    controlName: string
) => {
    const control: AbstractControl = form.get(controlName);
    if (control) {
        let isRequired = false;
        if (control.validator) {
            isRequired = control.validator({} as AbstractControl)?.required;
        }
        const isEmpty = lodash.isNil(control.value);
        const isPristine = control.pristine;
        const showRedPencil = isEmpty && isRequired && isPristine;
        return showRedPencil;
    }
};
